<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- Target Videos row -->
      <b-row
        class="d-flex flex-row align-items-stretch mb-3 justify-content-center w-100"
        no-gutters
      >
        <b-card
          no-body
          class="overflow-hidden border border-light shadow-sm w-100 card-container-first"
        >
          <b-row class="px-3 py-0 first-row" no-gutters>
            <!-- right column -->
            <b-col class="px-0 px-md-2 py-3">
              <!-- Privacy row -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-start"
                no-gutters
              >
                <b-col
                  class="d-flex flex-column align-items-start justify-content-start px-lg-3"
                >
                  <!-- Add a New FAQ form -->
                  <h6
                    class="text-left text-dark font-weight-semi-bold main-heading mb-3 mt-3"
                  >
                    Choose your color theme
                  </h6>

                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100 py-2"
                    no-gutters
                  >
                    <!-- last month's numbers -->
                    <b-col class="px-3 px-lg-0 py-3 left-column">
                      <h6 class="font-primary title text-dark">
                        Primary color
                      </h6>
                      <b-row
                        class="d-flex flex-row align-items-start justify-content-start w-100 py-2"
                        no-gutters
                      >
                        <b-col
                          class="d-flex flex-column align-start justify-start flex-grow-0 mb-lg-0 mb-3 layout-column mr-2"
                          v-for="(item, index) in primaryColors"
                          :key="index"
                        >
                          <b-button
                            :variant="item.color"
                            :id="index"
                            class="d-flex flex-column flex-shrink-0 align-items-center justify-content-center shadow-sm rounded-lg color-btn"
                            @click="primaryColorClick(item, $event.target)"
                          >
                            <b-icon
                              variant="white"
                              :icon="item.selected ? 'check2' : ''"
                            ></b-icon>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>

                    <!-- this month's numbers -->
                    <b-col class="px-3 px-lg-0 py-3 right-column">
                      <h6 class="font-primary title text-dark">
                        Secondary color
                      </h6>
                      <b-row
                        class="d-flex flex-row align-items-start justify-content-start w-100 py-2"
                        no-gutters
                      >
                        <b-col
                          class="d-flex flex-column align-start justify-start mr-2 flex-grow-0 mb-lg-0 mb-3 layout-column"
                          v-for="(item, index) in secondaryColors"
                          :key="index"
                        >
                          <b-button
                            :variant="item.color"
                            class="d-flex flex-column flex-shrink-0 align-items-center justify-content-center shadow-sm rounded-lg color-btn"
                            @click="secondaryColorClick(item, $event.target)"
                          >
                            <b-icon
                              variant="white"
                              :icon="item.selected ? 'check2' : ''"
                            ></b-icon>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- overlay loader -->
                <b-overlay
                  :show="isPrimaryLoading || isSecondaryLoading"
                  class="mx-n3"
                  no-wrap
                ></b-overlay>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <!-- Compliance slides row -->
      <b-row
        class="d-flex flex-column align-items-start justify-content-start px-0 w-100"
        no-gutters
      >
        <b-card
          no-body
          class="overflow-hidden border border-light shadow-sm w-100 card-container"
        >
          <!-- First row -->
          <b-row class="px-3 py-0 second-row" no-gutters>
            <!-- left column -->
            <b-col class="px-0 px-md-2 py-3 left-column">
              <b-row
                class="d-flex flex-row align-items-start justify-content-start"
                no-gutters
              >
                <b-col
                  class="d-flex flex-column align-items-start justify-content-start"
                >
                  <!-- Add a New FAQ form -->
                  <h6
                    class="text-left text-dark font-weight-semi-bold main-heading mb-3 mt-3"
                  >
                    Logo
                  </h6>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100 py-2"
                    no-gutters
                  >
                    <div v-if="!logoImageUrl">
                      <b-button
                        variant="light"
                        class="d-flex flex-column flex-shrink-0 align-items-center justify-content-center mr-2 bg-white border border-light shadow-sm rounded-lg logo-add-btn"
                        @click="OpenLogoModal($event.target)"
                      >
                        <font-awesome-icon
                          class="text-prime-gray"
                          icon="plus"
                          size="lg"
                          aria-label="Create Prize"
                        ></font-awesome-icon>
                        <span class="d-inline mt-2 text-prime-gray"
                          >Add the logo</span
                        >
                      </b-button>
                      <input
                        type="file"
                        style="display: none"
                        ref="logoFileInput"
                        accept="image/*"
                        @change="selectedLogo"
                      />
                    </div>
                    <div v-else>
                      <!-- <b-button
                        variant="light"
                        class="
                          d-flex
                          flex-column flex-shrink-0
                          align-items-center
                          justify-content-center
                          mr-2
                          bg-white
                          border border-light
                          shadow-sm
                          rounded-lg
                          logo-add-btn
                        "
                        @click="selectLogoFile"
                      >
                        <b-img
                          v-if="logoImageUrl"
                          class="logo-add-btn"
                          :src="logoImageUrl"
                        />
                      </b-button>
                      <input
                        type="file"
                        style="display: none"
                        ref="logoFileInput"
                        accept="image/*"
                        @change="selectedLogo"
                      /> -->
                      <b-card
                        overlay
                        :img-src="logoImageUrl"
                        class="d-flex flex-column flex-shrink-0 align-items-start justify-content-center bg-light rounded-lg border border-light shadow-sm overflow-hidden mr-2 my-2 logo-add-btn"
                        body-class="d-flex flex-column align-items-start justify-content-end px-3"
                      >
                        <b-row
                          class="d-flex flex-row align-items-start justify-content-end hover-actions"
                          no-gutters
                        >
                          <b-col
                            class="d-flex flex-column align-items-end justify-content-start mx-2 my-1"
                          >
                            <!-- edit slide button -->
                            <b-button
                              v-b-tooltip.hover.left
                              title="Edit Logo"
                              size="sm"
                              class="shadow-none my-1 border border-info edit-btn"
                              pill
                              @click="OpenLogoModal($event.target)"
                            >
                              <font-awesome-icon
                                icon="pen"
                                aria-label="Edit Slide"
                                class="edit-icon"
                              ></font-awesome-icon>
                            </b-button>
                            <!-- delete slide button -->
                            <b-button
                              v-b-tooltip.hover.left
                              title="Delete Logo"
                              size="sm"
                              class="shadow-none my-1 border border-danger delete-btn"
                              pill
                              @click="
                                openDeleteItemModal(
                                  logoImageUrl,
                                  'logo',
                                  $event.target
                                )
                              "
                            >
                              <font-awesome-icon
                                icon="trash"
                                aria-label="Delete Slide"
                                class="delete-icon"
                              ></font-awesome-icon>
                            </b-button>
                          </b-col>
                        </b-row>
                        <input
                          type="file"
                          style="display: none"
                          ref="logoFileInput"
                          accept="image/png, image/jpeg"
                          @change="selectedLogo"
                        />
                      </b-card>
                      <!--   <div
                        v-if="logoFileError.status == false"
                        class="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        {{ logoFileError.message }}
                        <button
                          @click="logoFileError.status = null"
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div> -->
                    </div>
                  </b-row>
                </b-col>
                <!-- overlay loader -->
                <b-overlay
                  :show="isLogoLoading"
                  class="mx-n3"
                  no-wrap
                ></b-overlay>
              </b-row>
            </b-col>
            <!-- column divider -->
            <div class="second-column-divider">
              <!-- right column -->
              <b-col class="px-0 px-md-2 py-3 right-column">
                <!-- Privacy row -->
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start"
                  no-gutters
                >
                  <b-col
                    class="d-flex flex-column align-items-start justify-content-start"
                  >
                    <!-- Add a New FAQ form -->
                    <h6
                      class="text-left text-dark font-weight-semi-bold main-heading mb-2 mt-3 px-lg-3"
                    >
                      Banner
                    </h6>

                    <b-row
                      class="d-flex flex-row align-items-start justify-content-start w-100 py-2 px-lg-3"
                      no-gutters
                    >
                      <div v-if="!bannerImageUrl">
                        <b-button
                          variant="light"
                          class="d-flex flex-column flex-shrink-0 align-items-center justify-content-center mr-2 bg-white border border-light shadow-sm rounded-lg banner-add-btn"
                          @click="OpenBannerModal($event.target)"
                        >
                          <font-awesome-icon
                            class="text-prime-gray"
                            icon="plus"
                            size="lg"
                            aria-label="Create Prize"
                          ></font-awesome-icon>
                          <span class="d-inline mt-2 text-prime-gray"
                            >Add the Banner Image</span
                          >
                        </b-button>
                        <input
                          type="file"
                          style="display: none"
                          ref="bannerFileInput"
                          accept="image/*"
                        />
                      </div>
                      <div v-else>
                        <!-- <b-button
                          variant="light"
                          class="
                            d-flex
                            flex-column flex-shrink-0
                            align-items-center
                            justify-content-center
                            mr-2
                            bg-white
                            border border-light
                            shadow-sm
                            rounded-lg
                            banner-add-btn
                          "
                          @click="OpenBannerModal($event.target)"
                        >
                          <b-img class="banner-add-btn" :src="bannerImageUrl" />
                        </b-button>
                      </div> -->
                        <b-card
                          overlay
                          :img-src="bannerImageUrl"
                          class="d-flex flex-column flex-shrink-0 align-items-start justify-content-center bg-light rounded-lg border border-light shadow-sm overflow-hidden mr-2 my-2"
                          :class="
                            bannerType == 'square'
                              ? 'logo-add-btn mt-3'
                              : 'banner-add-btn'
                          "
                          body-class="d-flex flex-column align-items-start justify-content-end px-3"
                        >
                          <b-row
                            class="d-flex flex-row align-items-start justify-content-end hover-actions"
                            no-gutters
                          >
                            <b-col
                              class="d-flex flex-column align-items-end justify-content-start mx-2 my-1"
                            >
                              <!-- edit slide button -->
                              <b-button
                                v-b-tooltip.hover.left
                                title="Edit Banner"
                                size="sm"
                                class="shadow-none my-1 border border-info edit-btn"
                                pill
                                @click="OpenBannerModal($event.target)"
                              >
                                <font-awesome-icon
                                  icon="pen"
                                  aria-label="Edit Banner"
                                  class="edit-icon"
                                ></font-awesome-icon>
                              </b-button>
                              <!-- delete slide button -->
                              <b-button
                                v-b-tooltip.hover.left
                                title="Delete Banner"
                                size="sm"
                                class="shadow-none my-1 border border-danger delete-btn"
                                pill
                                @click="
                                  openDeleteItemModal(
                                    bannerImageUrl,
                                    'banner',
                                    $event.target
                                  )
                                "
                              >
                                <font-awesome-icon
                                  icon="trash"
                                  aria-label="Delete Slide"
                                  class="delete-icon"
                                ></font-awesome-icon>
                              </b-button>
                            </b-col>
                          </b-row>
                          <input
                            type="file"
                            style="display: none"
                            ref="logoFileInput"
                            accept="image/*"
                            @change="selectedLogo"
                          />
                        </b-card>
                      </div>
                    </b-row>
                  </b-col>
                  <!-- overlay loader -->
                  <b-overlay
                    :show="isBannerLoading"
                    class="mx-n3"
                    no-wrap
                  ></b-overlay>
                </b-row>
              </b-col>
            </div>
          </b-row>
        </b-card>
      </b-row>

      <!-- Compliance slide configuration row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100"
        no-gutters
      >
        <b-card
          no-body
          class="d-flex flex-row flex-nowrap align-items-start justify-content-start w-100 card-container-bottom"
        >
          <b-overlay :show="isBackgroundImgLoading" no-wrap></b-overlay>
          <!-- <b-row class="px-3 py-0 third-row" no-gutters> -->
          <b-col class="px-3 px-lg-4 py-2 left-column" md="11" cols="12">
            <b-row
              class="d-flex flex-row align-items-start justify-content-start"
              no-gutters
            >
              <b-col
                class="d-flex flex-column align-items-start justify-content-start"
                ><h6
                  class="text-left text-dark font-weight-semi-bold main-heading mb-2 mt-3 px-lg-0 px-2"
                >
                  Background Image
                </h6>

                <!-- Add a New FAQ form -->
                <b-row
                  class="d-flex flex-row align-items-start justify-content-start w-100 py-2 px-lg-0 px-2"
                  no-gutters
                  ><b-col
                    class="d-flex flex-column align-items-start justify-content-start"
                  >
                    <div v-if="!backgroundImageUrl">
                      <b-button
                        variant="light"
                        class="d-flex flex-column flex-shrink-0 align-items-center justify-content-center mr-2 bg-white border border-light shadow-sm rounded-lg background-add-btn"
                        @click="OpenBackgroundModal($event.target)"
                      >
                        <font-awesome-icon
                          class="text-prime-gray"
                          icon="plus"
                          size="lg"
                          aria-label="Create Prize"
                        ></font-awesome-icon>
                        <span class="d-inline mt-2 text-prime-gray"
                          >Add the background Image</span
                        >
                      </b-button>
                      <input
                        type="file"
                        style="display: none"
                        ref="backgroundFileInput"
                        accept="image/*"
                        @change="selectedBackgroundImg"
                      />
                    </div>
                    <div v-else>
                      <!-- <b-button
                        variant="light"
                        class="
                          d-flex
                          flex-column flex-shrink-0
                          align-items-center
                          justify-content-center
                          mr-2
                          bg-white
                          border border-light
                          shadow-sm
                          rounded-lg
                          background-add-btn
                        "
                        @click="selectedBackgroundFile"
                      >
                        <b-img
                          v-if="backgroundImageUrl"
                          class="background-add-btn"
                          :src="backgroundImageUrl"
                        />
                      </b-button>
                      <input
                        type="file"
                        style="display: none"
                        ref="backgroundFileInput"
                        accept="image/*"
                        @change="selectedBackgroundImg"
                      /> -->
                      <b-card
                        overlay
                        :img-src="backgroundImageUrl"
                        class="d-flex flex-column flex-shrink-0 align-items-start justify-content-center bg-light rounded-lg border border-light shadow-sm overflow-hidden mr-2 my-2 background-add-btn"
                        body-class="d-flex flex-column align-items-start justify-content-end px-3"
                      >
                        <b-row
                          class="d-flex flex-row align-items-start justify-content-end hover-actions"
                          no-gutters
                        >
                          <b-col
                            class="d-flex flex-column align-items-end justify-content-start mx-2 my-1"
                          >
                            <!-- edit slide button -->
                            <b-button
                              v-b-tooltip.hover.left
                              title="Edit Background Image"
                              size="sm"
                              class="shadow-none my-1 border border-info edit-btn"
                              pill
                              @click="OpenBackgroundModal($event.target)"
                            >
                              <font-awesome-icon
                                icon="pen"
                                aria-label="Edit Slide"
                                class="edit-icon"
                              ></font-awesome-icon>
                            </b-button>
                            <!-- delete slide button -->
                            <b-button
                              v-b-tooltip.hover.left
                              title="Delete Background Image"
                              size="sm"
                              class="shadow-none my-1 border border-danger delete-btn"
                              pill
                              @click="
                                openDeleteItemModal(
                                  backgroundImageUrl,
                                  'backgroundImg',
                                  $event.target
                                )
                              "
                            >
                              <font-awesome-icon
                                icon="trash"
                                aria-label="Delete Slide"
                                class="delete-icon"
                              ></font-awesome-icon>
                            </b-button>
                          </b-col>
                        </b-row>
                        <input
                          type="file"
                          style="display: none"
                          ref="backgroundFileInput"
                          accept="image/*"
                          @change="selectedBackgroundImg"
                        />
                      </b-card>
                      <div
                        v-if="backgroundFileError.status == false"
                        class="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        {{ backgroundFileError.message }}
                        <button
                          @click="backgroundFileError.status = null"
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <!-- overlay loader -->

              <b-col class="d-flex flex-column" md="7" cols="12" lg="4"
                ><div class="guid-text-mobile">
                  <h6
                    class="text-dark main-heading font-weight-semi-bold mb-2 mt-3 px-lg-4"
                  >
                    Image guidlines
                  </h6>
                  <template>
                    <b-row>
                      <b-col class="d-flex flex-column">
                        <ul class="text-left mt-2">
                          <small>
                            <li class="sub-points">
                              Logo images must be at least
                              <span class="font-weight-bold"> 250x150px</span>
                              in size.
                            </li>
                            <li class="sub-points">
                              Background image must be at least
                              <span class="font-weight-bold">1440x1024px</span>
                              in size.
                            </li>
                            <li class="sub-points">
                              JPEG is the preferred format, but you may also use
                              PNG.
                            </li>
                            <li class="sub-points">
                              Ensure that the images have a clean background.
                            </li>
                            <li class="sub-points">
                              Images must be less than 5MB in size.
                            </li>
                          </small>
                        </ul>
                      </b-col>
                    </b-row>
                  </template>
                </div>
              </b-col>
            </b-row>
            <!-- overlay loader -->
          </b-col>
          <!-- </b-row> -->
          <BannerModal
            :isShow="isShowBannerModal"
            @onClose="onCloseBannerModal"
            @reloadFn="callBannerInitFn"
            :key="showBannerModalKey"
          />
          <LogoModal
            :isShow="isShowLogoModal"
            @onClose="onCloseLogoModal"
            @reloadFn="callLogoInitFn"
            :key="showLogoModalKey"
          />

          <BackgroundModal
            :isShow="isShowBackgroundModal"
            @onClose="onCloseBackgroundModal"
            @reloadFn="callBackgroundInitFn"
            :key="showBackgroundModalKey"
          />
          <DeleteModal
            :isShow="isShowDeleteModal"
            @onClose="onCloseDeleteItemModal"
            @deleteLogo="deleteLogoFn"
            @deleteBanner="deleteBannerFn"
            @deleteBackgroundImg="deleteBackgroundImgFn"
            :apiLoader="deleteApiLoading"
            :itemContent="deleteItem"
            :type="deleteType"
          />
        </b-card>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-stretch mt-3 justify-content-center w-100"
        no-gutters
      >
        <b-card
          no-body
          class="overflow-hidden border border-light shadow-sm w-100 card-container-first"
        >
          <b-row class="px-1 py-0 first-row" no-gutters>
            <!-- right column -->
            <b-col class="px-3 px-lg-0 px-md-2 py-3">
              <!-- Privacy row -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-start"
                no-gutters
              >
                <b-col
                  class="d-flex flex-column align-items-start justify-content-start px-lg-3"
                >
                  <!-- Add a New FAQ form -->
                  <h6
                    class="text-left text-dark font-weight-semi-bold main-heading mb-3 mt-3"
                  >
                    Donor's Video Email Text
                  </h6>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100 py-2"
                    no-gutters
                  >
                    <b-col cols="12">
                      <!-- <FormInput
                        groupId="company-vision-text-group"
                        id="company-vision-text-group-input"
                        class="text-prime-gray"
                        :state="videoCustomTextError.status"
                        :invalidFeedback="`${videoCustomTextError.message}`"
                        label="Text"
                        v-model="videoCustomText"
                        isRequired
                        isTextArea
                        trim
                        :counter="videoCustomTextCount"
                        form="what-we-do-form"
                        type="text"
                      ></FormInput> -->

                      <vue-editor
                        v-model="videoCustomText"
                        :editor-toolbar="customToolbar"
                        placeholder="Text"
                      >
                      </vue-editor>
                    </b-col>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-center justify-content-center w-100"
                    no-gutters
                  >
                    <!-- reset password button -->
                    <b-col
                      class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
                      cols="9"
                      sm="6"
                    >
                      <FormButton
                        variant="primary"
                        isBlock
                        class="font-secondary font-weight-normal text-white mt-2"
                        type="submit"
                        :isLoading="isLoading"
                        @click.native="customTxtBtn"
                        >Save Changes</FormButton
                      >
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
// store
// import { mapGetters } from "vuex";

// components
import BannerModal from "@/components/CampaignTemplate/BannerModal";
import LogoModal from "@/components/CampaignTemplate/LogoModal";
import BackgroundModal from "@/components/CampaignTemplate/BackgroundModal";
import HomeLayout from "@/layout/HomeLayout";
import DeleteModal from "@/components/CampaignTemplate/DeleteModal";
//import FormInput from "@/components/Form/FormInput";
import FormButton from "@/components/Form/FormButton";

//services
import {
  CreateLayout,
  UpdateLogo,
  UpdateBackgroundImage,
  GetData,
  DeleteLogo,
  DeleteBanner,
  DeleteBackgroundImg,
} from "@/services/campaignTemplate.service";

export default {
  title: "Videos",
  components: {
    HomeLayout,
    BannerModal,
    LogoModal,
    BackgroundModal,
    DeleteModal,
    //FormInput,
    FormButton,
  },
  props: ["selectedVal"],
  data() {
    return {
      isAPILoading: false,
      isShowDeleteModal: false,
      deleteApiLoading: false,
      primaryColors: [
        { color: "primary" },
        { color: "secondary" },
        { color: "dark-green" },
        { color: "dark-yellow" },
        { color: "dark-purple" },
        { color: "sky-blue" },
      ],
      secondaryColors: [
        { color: "black" },
        { color: "dark" },
        { color: "light-gray" },
        { color: "light-soft" },
        { color: "white" },
      ],
      isPrimaryColorClick: false,
      isSecondaryColorClick: false,
      logoImageUrl: null,
      logoImage: null,
      isShowLogoModal: false,
      backgroundImageUrl: null,
      bannerImage: null,
      bannerImageUrl: null,
      backgroundImage: null,
      isShowBackgroundModal: false,
      isShowBannerModal: false,
      showBannerModalKey: Number(new Date()) + 100,
      showLogoModalKey: Number(new Date()) + 10,
      showBackgroundModalKey: Number(new Date()) + 1000,
      isPrimaryLoading: false,
      isSecondaryLoading: false,
      isLogoLoading: false,
      isBannerLoading: false,
      isBackgroundImgLoading: false,
      selectedShape: this.selectedVal,
      backgroundFileError: {
        status: null,
        message: "",
      },
      logoFileError: {
        status: null,
        message: "",
      },
      deleteItem: null,
      deleteType: null,
      bannerType: null,
      videoCustomText: null,
      videoCustomTextError: {
        status: null,
        message: "",
      },
      videoCustomTextCount: 160,
      isLoading: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
    };
  },
  watch: {
    videoCustomText(val) {
      console.log(val);
      if (val) {
        this.videoCustomTextError.status = true;
        if (val.length <= this.videoCustomTextCount) {
          this.videoCustomTextError.status = true;
        } else {
          this.videoCustomTextError.status = false;
          this.videoCustomTextError.message = `Message should have less than ${this.videoCustomTextCount} characters`;
        }
      } else {
        this.videoCustomTextError.status = false;
        this.videoCustomTextError.message = "Please fill in the message text";
      }
    },
  },
  async mounted() {
    // start and end the loading when all the APIs are called
    this.isAPILoading = true;
    (this.isPrimaryLoading = true),
      (this.isSecondaryLoading = true),
      (this.isLogoLoading = true),
      (this.isBannerLoading = true),
      (this.isBackgroundImgLoading = true);
    await this.initFn();
    this.isAPILoading = false;
    (this.isPrimaryLoading = false),
      (this.isSecondaryLoading = false),
      (this.isLogoLoading = false),
      (this.isBannerLoading = false),
      (this.isBackgroundImgLoading = false);
  },
  methods: {
    async initFn() {
      try {
        this.isAPILoading = true;
        let { data } = await GetData();
        if (data.code == 200 || data.message == "success") {
          let details = data.content;

          this.logoImageUrl = details.logo ? details.logo[0] : null;
          this.bannerType = details.bannerType ? details.bannerType : null;
          this.backgroundImageUrl = details.backgroundImage
            ? details.backgroundImage[0]
            : null;
          this.bannerImageUrl = details.bannerImage
            ? details.bannerImage[0]
            : null;

          (this.videoCustomText = details.mailText),
            (this.primaryColors = this.primaryColors.map((color) => {
              return {
                color: color.color,
                selected: color.color == details.primaryColor,
              };
            }));

          this.secondaryColors = this.secondaryColors.map((color) => {
            return {
              color: color.color,
              selected: color.color == details.secondaryColor,
            };
          });
        } else {
          this.isAPILoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign details",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.isAPILoading = false;
        console.log("ERROR C01");
      }
    },

    async primaryColorClick(item) {
      this.isPrimaryColorClick = !this.isPrimaryColorClick;

      this.primaryColors = this.primaryColors.map((color) => {
        return {
          color: color.color,
          selected: color.color == item.color,
        };
      });

      try {
        this.isPrimaryLoading = true;
        let payload = {
          primaryColor: item.color,
        };

        let { data } = await CreateLayout(payload);

        if (data && data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Campaign Template",
            content: "Primary color was changed successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign Template",
            content: `Unable to save primary color! ${
              data ? data.msg : "Please try again."
            }.`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Campaign Template",
          content: "Failed to save data! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isPrimaryLoading = false;
      }
    },
    async secondaryColorClick(item) {
      this.isSecondaryColorClick = !this.isSecondaryColorClick;

      this.secondaryColors = this.secondaryColors.map((color) => {
        return {
          color: color.color,
          selected: color.color == item.color,
        };
      });

      try {
        this.isSecondaryLoading = true;
        let payload = {
          secondaryColor: item.color,
        };

        let { data } = await CreateLayout(payload);

        if (data && data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Campaign Template",
            content: "Secondary color was saved successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign Template",
            content: `Unable to save secondary color! ${
              data ? data.msg : "Please try again."
            }.`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Campaign Template",
          content: "Failed to save data! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isSecondaryLoading = false;
      }
    },
    selectLogoFile() {
      this.$refs.logoFileInput.click();
    },
    async selectedLogo(event) {
      const files = event.target.files;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      this.logoImage = files[0];

      if (
        files[0].type &&
        files[0].type != "image/jpeg" &&
        files[0].type != "image/png"
      ) {
        this.logoFileError.status = false;
        this.logoFileError.message =
          "Please upload a valid image file in either .jpeg or .png formats";
        let payloadNotify = {
          isToast: true,
          title: "Logo uploading failed",
          content:
            "Please upload a valid image file in either .jpeg or .png formats",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        return;
      } else {
        this.logoFileError.status = true;
      }
      if (
        files[0].size &&
        files[0].size > 5 * 1024 * 1024 //5 mb
      ) {
        this.logoFileError.status = false;
        this.logoFileError.message =
          "Please upload an image file that is less than 5MB in size";
        let payloadNotify = {
          isToast: true,
          title: "Logo uploading failed",
          content: "Please upload an image file that is less than 5MB in size",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        return;
      } else {
        this.logoFileError.status = true;
      }

      fileReader.addEventListener("load", () => {
        this.logoImageUrl = fileReader.result;
      });

      const formData = new FormData();

      formData.append("logo", files[0]);

      try {
        this.isLogoLoading = true;

        let { data } = await UpdateLogo(formData);

        if (data && data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Campaign Template",
            content: "Logo was saved successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign Template",
            content: `Unable to save logo! ${
              data ? data.msg : "Please try again."
            }.`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Campaign Template",
          content: "Failed to save data! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isLogoLoading = false;
      }
    },
    selectedBackgroundFile() {
      this.$refs.backgroundFileInput.click();
    },
    async selectedBackgroundImg(event) {
      const files = event.target.files;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      this.backgroundImage = files[0];

      const formData = new FormData();
      formData.append("backgroundImage", files[0]);

      if (
        files[0].type &&
        files[0].type != "image/jpeg" &&
        files[0].type != "image/png"
      ) {
        this.backgroundFileError.status = false;
        this.backgroundFileError.message =
          "Please upload a valid image file in either .jpeg or .png formats";
        let payloadNotify = {
          isToast: true,
          title: "Background image uploading failed",
          content:
            "Please upload a valid image file in either .jpeg or .png formats",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        return;
      } else {
        this.backgroundFileError.status = true;
      }
      if (
        files[0].size &&
        files[0].size > 5 * 1024 * 1024 //5 mb
      ) {
        this.backgroundFileError.status = false;
        this.backgroundFileError.message =
          "Please upload an image file that is less than 5MB in size";
        let payloadNotify = {
          isToast: true,
          title: "Background image uploading failed",
          content: "Please upload an image file that is less than 5MB in size",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        return;
      } else {
        this.backgroundFileError.status = true;
      }

      fileReader.addEventListener("load", () => {
        this.backgroundImageUrl = fileReader.result;
      });

      try {
        this.isBackgroundImgLoading = true;
        let { data } = await UpdateBackgroundImage(formData);

        if (data && data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Campaign Template",
            content: "Background image was saved successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign Template",
            content: `Unable to save background image! ${
              data ? data.msg : "Please try again."
            }.`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Campaign Template",
          content: "Failed to save background image! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isBackgroundImgLoading = false;
      }
    },
    //for Banner  Model
    OpenBannerModal(button) {
      this.isShowBannerModal = true;
      this.$root.$emit("bv::show::modal", "banner-modal", button);
    },
    onCloseBannerModal() {
      // this.$root.$emit("bv::hide::modal", "banner-modal");
      this.isShowBannerModal = false;
    },
    async callBannerInitFn() {
      this.showBannerModalKey = Number(new Date()) + 100;
      this.isBannerLoading = true;
      await this.initFn();
      this.isBannerLoading = false;
    },

    //for logo model
    OpenLogoModal(button) {
      this.isShowLogoModal = true;
      this.$root.$emit("bv::show::modal", "logo-modal", button);
    },
    onCloseLogoModal() {
      this.isShowLogoModal = false;
    },
    async callLogoInitFn() {
      this.showLogoModalKey = Number(new Date()) + 10;
      this.isLogoLoading = true;
      await this.initFn();
      this.isLogoLoading = false;
    },

    //for background model
    OpenBackgroundModal(button) {
      this.isShowBackgroundModal = true;
      this.$root.$emit("bv::show::modal", "background-modal", button);
    },
    onCloseBackgroundModal() {
      this.isShowBackgroundModal = false;
    },
    async callBackgroundInitFn() {
      this.showBackgroundModalKey = Number(new Date()) + 1000;
      this.isBackgroundImgLoading = true;
      await this.initFn();
      this.isBackgroundImgLoading = false;
    },

    // delete modal functions
    openDeleteItemModal(item, type, button) {
      this.isShowDeleteModal = true;
      this.deleteItem = item;
      // set the type of delete (video or slide)
      this.deleteType = type;
      this.modalThatBtn = button;
      this.$root.$emit("bv::show::modal", "delete-video-row-modal", button);
    },
    onCloseDeleteItemModal() {
      this.isShowDeleteModal = false;
    },
    // delete functions
    async deleteLogoFn() {
      try {
        // start the delete api loader
        this.deleteApiLoading = true;
        let { data } = await DeleteLogo();
        if (data.code == 200 || data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Campaign Template",
            content: `The logo has been removed successfully.`,
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          // load all slides then close modal
          await this.initFn();
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign Template",
            content: `Unable to delete the logo).`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        // stop the delete api loader
        this.deleteApiLoading = false;
        // then close the modal
        this.closeDeleteModal();
      } catch (error) {
        console.log("ERROR C02");
      }
    },
    async deleteBannerFn() {
      try {
        // start the delete api loader
        this.deleteApiLoading = true;
        let { data } = await DeleteBanner();
        if (data.code == 200 || data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Campaign Template",
            content: `The banner has been removed successfully.`,
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          // load all slides then close modal
          await this.initFn();
          await this.callBannerInitFn();
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign Template",
            content: `Unable to delete the banner).`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        // stop the delete api loader
        this.deleteApiLoading = false;
        // then close the modal
        this.closeDeleteModal();
      } catch (error) {
        console.log("ERROR C03");
      }
    },
    async deleteBackgroundImgFn() {
      try {
        // start the delete api loader
        this.deleteApiLoading = true;
        let { data } = await DeleteBackgroundImg();
        if (data.code == 200 || data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Campaign Template",
            content: `The background image has been removed successfully.`,
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          // load all slides then close modal
          await this.initFn();
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign Template",
            content: `Unable to delete the background image).`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        // stop the delete api loader
        this.deleteApiLoading = false;
        // then close the modal
        this.closeDeleteModal();
      } catch (error) {
        console.log("ERROR C04");
      }
    },
    closeDeleteModal() {
      this.$root.$emit(
        "bv::hide::modal",
        "delete-video-row-modal",
        this.modalThatBtn
      );
    },
    async customTxtBtn() {
      try {
        this.isLoading = true;
        let payload = {
          mailText: this.videoCustomText,
        };

        let { data } = await CreateLayout(payload);

        if (data && data.code == 200 && data.message == "success") {
          let payloadNotify = {
            isToast: true,
            title: "Campaign Template",
            content: "Custom text was saved successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
        } else {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign Template",
            content: `Unable to save custom text! ${
              data ? data.msg : "Please try again."
            }.`,
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        let payloadNotify = {
          isToast: true,
          title: "ERROR! Campaign Template",
          content: "Failed to save data! please check again: ",
          list: [error.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.layout-image-placeholder {
  height: 118px;
  width: 120px;
  border-radius: 5px;
  background-color: #ffffff;
}
.layout-column {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.layout-image-placeholder.selectable-card {
  cursor: pointer;
  opacity: 1;
  transition: opacity 150ms ease-in;
}
.layout-image-placeholder.selectable-card:hover {
  opacity: 0.75;
  border: 2px solid var(--primary);
}
.body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
}
.color-btn {
  width: 35px;
  height: 35px;
}
.column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}
.title {
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
}
.main-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}
.card-container {
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  border-radius: 16px 16px 0 0;
  width: 100%;
}
.card-container-first {
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  border-radius: 16px 16px 16px 16px;
  width: 100%;
}
.card-container-bottom {
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  border-radius: 0 0 16px 16px;
  width: 100%;
}
.body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  border-radius: 0px 0px 0px 0px;
}
.first-row {
  height: 100%;
}
.second-row {
  height: 100%;
}
.third-row {
  height: 100%;
  /* border: 1px solid rgba(135, 147, 163, 0.25); */
  /* box-sizing: border-box;
  border-radius: 0px 0px 0px 0px; */
}
.fifth-row {
  height: 100%;
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  border-radius: 0px 0px 0px 0px;
}
.body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}
.first-column-divider {
  width: 628px;
  height: 100%;
  border-top: none;
  border-left: 2px solid var(--light);
}
.second-column-divider {
  width: 868px;
  height: auto;
  border-top: none;
  border-left: 2px solid var(--light);
}
.logo-add-btn {
  width: 180px;
  height: 180px;
  border-radius: 8px;
  transition: background-color 150ms ease-in;
  cursor: pointer;
}
/* .logo-add-btn:hover {
  background-color: var(--light) !important;
} */
.banner-add-btn {
  width: 800px;
  height: 200px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 150ms ease-in;
}
.background-add-btn {
  width: 600px;
  height: 200px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 150ms ease-in;
}
.banner-add-btn:hover {
  background-color: var(--light) !important;
}
.hero-banner-add-btn {
  height: 160px;
  width: 1090px;
  max-width: 1090px;

  transition: background-color 150ms ease-in;
}
.hero-banner-add-btn:hover {
  background-color: var(--light) !important;
}
.subheadline-text.form-group >>> input.form-control,
.form-group >>> textarea.form-control {
  min-height: 120px;
}
.editor {
  height: 750px;
  max-height: calc(100vh - 120px);
  width: 100%;
}
.editor >>> .ql-tooltip {
  z-index: 50;
}
.logo-add-btn .hover-actions,
.banner-add-btn .hover-actions,
.background-add-btn .hover-actions {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 150ms ease-in;
}
.logo-add-btn:hover .hover-actions,
.banner-add-btn:hover .hover-actions,
.background-add-btn:hover .hover-actions {
  opacity: 1;
}
.logo-add-btn .hover-actions .delete-btn,
.banner-add-btn .hover-actions .delete-btn,
.background-add-btn .hover-actions .delete-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.logo-add-btn .hover-actions .delete-btn > .delete-icon,
.banner-add-btn .hover-actions .delete-btn > .delete-icon,
.background-add-btn .hover-actions .delete-btn > .delete-icon {
  width: 14px;
  height: 14px;
  color: var(--danger);
  transition: color 200ms ease-in;
}
.logo-add-btn .hover-actions .delete-btn:hover > .delete-icon,
.banner-add-btn .hover-actions .delete-btn:hover > .delete-icon,
.background-add-btn .hover-actions .delete-btn:hover > .delete-icon {
  color: var(--white);
}
.logo-add-btn .hover-actions .delete-btn:hover,
.banner-add-btn .hover-actions .delete-btn:hover,
.background-add-btn .hover-actions .delete-btn:hover {
  background-color: var(--danger);
}
.logo-add-btn .hover-actions .edit-btn > .edit-icon,
.banner-add-btn .hover-actions .edit-btn > .edit-icon,
.background-add-btn .hover-actions .edit-btn > .edit-icon {
  width: 14px;
  height: 14px;
  color: var(--info);
  transition: color 200ms ease-in;
}

.logo-add-btn .hover-actions .edit-btn:hover,
.banner-add-btn .hover-actions .edit-btn:hover,
.background-add-btn .hover-actions .edit-btn:hover {
  background-color: var(--info);
}
.logo-add-btn .hover-actions .edit-btn:hover > .edit-icon,
.banner-add-btn .hover-actions .edit-btn:hover > .edit-icon,
.background-add-btn .hover-actions .edit-btn:hover > .edit-icon {
  color: var(--white);
}

.logo-add-btn .hover-actions .edit-btn,
.banner-add-btn .hover-actions .edit-btn,
.background-add-btn .hover-actions .edit-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.logo-add-btn >>> div,
.banner-add-btn >>> div,
.background-add-btn >>> div {
  height: 100%;
  width: 100%;
}
.logo-add-btn >>> .card-img {
  min-width: 180px;
  min-height: 180px;
  object-fit: cover;
  transition: opacity 300ms ease-in;
}
.banner-add-btn >>> .card-img {
  min-width: 100%;
  min-height: 200px;
  object-fit: cover;
  transition: opacity 300ms ease-in;
}
.background-add-btn >>> .card-img {
  min-width: 100%;
  min-height: 200px;
  object-fit: cover;
  transition: opacity 300ms ease-in;
}
@media (max-width: 991.98px) {
  .guid-text {
    padding-left: 25px;
  }
  .hero-banner-add-btn {
    width: 100%;
  }
  .body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
  .first-column-divider {
    border-left: none;
  }
  .second-column-divider {
    border-left: none;
  }

  .body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
  .banner-add-btn {
    width: 100%;
  }
  .background-add-btn {
    width: 100%;
  }
  .guid-text-mobile {
    width: 100%;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .hero-banner-add-btn {
    width: 100%;
  }
  .second-column-divider {
    border-left: none;
  }
  .first-column-divider {
    border-left: none;
  }
  .banner-add-btn {
    width: 100%;
  }
  .guid-text-mobile {
    width: 100%;
  }
}
@media (max-width: 1024.98px) {
  .hero-banner-add-btn {
    width: 100%;
  }
  .second-column-divider {
    border-left: none;
  }
  .first-column-divider {
    border-left: none;
  }
}
</style>
