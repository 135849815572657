import Instance from "./instance";

export const getBlackListWords = async () => {
  console.log("method called");
  try {
    return await Instance.instanceToken.get(`/blacklist`);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addWordToBlackListLibrary = async (payload) => {
  console.log(payload);
  console.log("method called");
  try {
    return await Instance.instanceToken.post("/blacklist", payload);
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const deleteWordFromBlackListLibrary = async (payload) => {
  try {
    return await Instance.instanceToken.delete("/blacklist", {
      data:payload
    });
  } catch (error) {
    console.log(error);

    return error;
  }
};
