<template>
  <div>
    <!-- order items modal -->
    <b-modal
      id="logo-modal"
      ref="deleteItemModal"
      title="Logo Image Details"
      size="lg"
      header-class="font-primary px-4"
      header-bg-variant="white"
      header-text-variant="dark"
      header-border-variant="white"
      footer-bg-variant="gray-300"
      footer-border-variant="light"
      @hide="closeModal()"
      scrollable
    >
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <div
          class="
            d-flex
            flex-column flex-sm-row
            align-items-center
            justify-content-center justify-content-sm-end
            mx-0
            w-100
          "
        >
          <FormButton
            variant="light"
            isBlock
            class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary"
            @click.native="cancel"
            >Cancel</FormButton
          >
          <FormButton
            variant="danger"
            isBlock
            :isLoading="isLogoLoading || isTypeLoading"
            :disabled="isLogoLoading || isTypeLoading || hideBtn"
            class="my-2 my-sm-0 ml-0 ml-sm-2 font-weight-normal text-white"
            type="button"
            @click.native="submitFn"
            >Submit</FormButton
          >
        </div>
      </template>
      <!-- custom close icon -->
      <template v-slot:modal-header-close>
        <font-awesome-icon
          icon="times"
          class="text-secondary"
          @click="closeModal()"
          aria-label="Close Modal"
        ></font-awesome-icon>
      </template>
      <b-row
        class="
          d-flex
          flex-row
          align-items-start
          justify-content-start
          w-100
          px-2
        "
        no-gutters
      >
        <b-col cols="12">
          <ImageCropper
            :width="250"
            :height="150"
            @getBase64="getBase64CoverFn"
            :key="key"
            :initialImage="img[0]"
            :imageUrl="logoImagePropUrl"
          />
        </b-col>

        <b-overlay
          :show="isAPILoading || isLogoLoading || isTypeLoading"
          no-wrap
        ></b-overlay>
      </b-row>
      <b-row
        class="
          d-flex
          flex-row
          align-items-start
          justify-content-start
          w-100
          py-2
        "
        no-gutters
      >
        <div
          v-if="logoFileError.status == false"
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {{ logoFileError.message }}
          <!-- <button
          @click="logoFileError.status = null"
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button> -->
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
// services
import FormButton from "@/components/Form/FormButton";
import ImageCropper from "@/components/ImageUpload/ImageCropper";

import {
  UpdateLogo,
  GetData,
  // CreateLayout,
} from "@/services/campaignTemplate.service";
export default {
  name: "LogoModel",
  components: {
    FormButton,
    ImageCropper,
  },
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
  },
  data() {
    return {
      isAPILoading: false,
      isLogoLoading: false,
      isTypeLoading: false,
      logoImageUrl: null,
      logoFileError: {
        status: null,
        message: "",
      },
      hideBtn: false,
      logoImage: null,
      key: Number(new Date()),
      img: false,
      test: null,
      logoImagePropUrl: null,
    };
  },
  watch: {
    selected(val) {
      if (val) this.logoImageUrl = null;
      this.logoFileError.message = null;
      this.logoFileError.status = null;
    },
    // async bannerImageUrl(val) {
    //   const base64 = await fetch(val);
    //   const blob = await base64.blob();
    //   this.test = blob;
    // },
  },
  async mounted() {
    // start and end the loading when all the APIs are called
    this.isAPILoading = true;
    this.isLogoLoading = true;
    this.isTypeLoading = true;
    await this.initFn();
    this.isAPILoading = false;
    this.isLogoLoading = false;
    this.isTypeLoading = false;
  },
  methods: {
    getBase64CoverFn(val) {
      this.logoImageUrl = val;
    },
    closeModal() {
      this.$emit("onClose");
    },
    async submitFn() {
      if (this.logoImageUrl.chosenFile == null) {
        try {
          this.isLogoLoading = true;
          const formData = new FormData();
          try {
            formData.append("logo", this.logoImageUrl.generateDataUrl());
          } catch (e) {
            console.log(e);
          }
          let { data } = await UpdateLogo(formData);

          if (data && data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Campaign Template",
              content: "Data was saved successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.$emit("reloadFn");
            await this.initFn();
          } else {
            let payloadNotify = {
              isToast: true,
              title: "ERROR! Campaign Template",
              content: `Unable to save data! ${
                data ? data.msg : "Please try again."
              }.`,
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign Template",
            content: "Failed to save data! please check again: ",
            list: [error.message],
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } finally {
          this.isLogoLoading = false;
        }
      } else {
        var errResult = this.errorFn();
        if (errResult.length == 0) {
          try {
            this.isLogoLoading = true;
            const formData = new FormData();
            try {
              formData.append("logo", this.logoImageUrl.generateDataUrl());
            } catch (e) {
              console.log(e);
            }
            let { data } = await UpdateLogo(formData);

            if (data && data.code == 200 && data.message == "success") {
              let payloadNotify = {
                isToast: true,
                title: "Campaign Template",
                content: "Data was saved successfully",
                variant: "success",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
              this.$emit("reloadFn");
              await this.initFn();
            } else {
              let payloadNotify = {
                isToast: true,
                title: "ERROR! Campaign Template",
                content: `Unable to save data! ${
                  data ? data.msg : "Please try again."
                }.`,
                variant: "danger",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
            }
          } catch (error) {
            let payloadNotify = {
              isToast: true,
              title: "ERROR! Campaign Template",
              content: "Failed to save data! please check again: ",
              list: [error.message],
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          } finally {
            this.isLogoLoading = false;
          }
        } else this.handleSubmitError(errResult);
      }
    },
    handleSubmitError(errDetails) {
      this.isAPILoading = false;
      this.apiConfirmationText = null;

      let payloadNotify = {
        isToast: true,
        title: "ERROR! Unable to save data",
        // content: errDetails ,
        content: !Array.isArray(errDetails) ? errDetails : null,
        list: Array.isArray(errDetails) ? errDetails : null,
        variant: "danger",
      };

      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    async initFn() {
      try {
        this.isAPILoading = true;
        let { data } = await GetData();
        if (data.code == 200 || data.message == "success") {
          let details = data.content;
          this.logoImage = details.logo;
          this.logoImageUrl = details.logo ? details.logo[0] : null;
          this.logoImagePropUrl = this.logoImageUrl;
        } else {
          this.isAPILoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign details",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.isAPILoading = false;
        console.log("ERROR BM1");
      }
    },

    errorFn() {
      var errorList = [];
      if (!this.logoImageUrl) {
        this.logoFileError.status = false;
        this.logoFileError.message = "Please upload the logo image";

        errorList.push("Please upload the logo image");
      }

      // else {
      //   if ("File" in window && this.bannerImage instanceof File == false)
      //     errorList.push("Please upload a valid file as the banner file");

      if (
        this.logoImageUrl &&
        this.logoImageUrl.chosenFile.type != "image/jpeg" &&
        this.logoImageUrl.chosenFile.type != "image/png"
      )
        errorList.push(
          "Please upload a valid image file in either .jpeg or .png formats"
        );

      if (
        this.logoImageUrl &&
        this.logoImageUrl.chosenFile.size &&
        this.logoImageUrl.chosenFile.size > 5 * 1024 * 1024 //5 mb
      ) {
        errorList.push(
          "Please upload an image file that is less than 5MB in size"
        );
      }
      return errorList;
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}
.banner-add-btn {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 150ms ease-in;
}
.banner-add-btn:hover {
  background-color: var(--light) !important;
}
.custom-checkbox >>> .custom-control-label::before {
  border-color: var(--light-gray);
}
.form-group >>> select.custom-select {
  border-color: var(--gray-550);
}
.form-group >>> label {
  color: var(--muted-gray);
}
.form-group >>> .custom-select {
  color: var(--muted-gray);
}
.custom-checkbox
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: var(--light-gray);
}
.custom-checkbox
  >>> .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: var(--white);
  border-color: var(--light-gray);
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
