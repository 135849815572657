<template>
  <div>
    <Croppa
      :prevent-white-space="true"
      :show-remove-button="true"
      remove-button-color="black"
      :show-loading="true"
      placeholder="Click to upload"
      :placeholder-font-size="14"
      :width="width"
      :height="height"
      @new-image-drawn="imageAddedFn"
      @move="imageAddedFn"
      @zoom="imageAddedFn"
      v-model="image"
      initial-size="natural"
    >
      <img
        crossorigin="anonymous"
        slot="initial"
        v-if="initialImage"
        :src="initialImage"
    /></Croppa>
    <b-row
      class="
        d-flex
        flex-row
        align-items-start
        justify-content-start
        w-100
        mt-3
        px-3
        mb-lg-3
      "
    >
      <b-button class="mr-3" variant="primary" @click="image.zoomIn()"
        >+</b-button
      >
      <b-button @click="image.zoomOut()">-</b-button>
    </b-row>
  </div>
</template>

<script>
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
export default {
  name: "ImageCropper",
  components: {
    Croppa: Croppa.component,
  },
  props: {
    width: Number,
    height: Number,
    // initialImage: [String, Boolean],
    imageUrl: String,
  },
  data() {
    return {
      image: {},
      // imageUrl: null,
      initialImage: null,
    };
  },
  created() {
    if (this.imageUrl) {
      this.initialImage = this.imageUrl;
    }
  },
  watch: {
    image: {
      immediate: true,
      handler() {
        this.imageAddedFn();
      },
    },
  },
  methods: {
    imageAddedFn() {
      setTimeout(() => {
        this.$emit("getBase64", this.image);
      }, 100);
    },
  },
};
</script>
<style scoped>
.croppa-container {
  border-color: var(--prime-gray);
  border-radius: 8px;
  /* inherit fix (set to initial to reset) */
  line-height: initial;
  margin-right: 20px;
}
.croppa-container >>> canvas {
  border-radius: 8px;
  overflow: hidden;
}
.croppa-container >>> svg.icon.icon-remove {
  width: 30px;
  height: 30px;
  margin-right: 0px;
}
</style>