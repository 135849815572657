<template>
  <div>
    <!-- order items modal -->
    <b-modal
      id="banner-modal"
      ref="deleteItemModal"
      title="Banner Details"
      size="xl"
      header-class="font-primary px-4"
      header-bg-variant="white"
      header-text-variant="dark"
      header-border-variant="white"
      footer-bg-variant="gray-300"
      footer-border-variant="light"
      @hide="closeModal()"
      scrollable
    >
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <div
          class="
            d-flex
            flex-column flex-sm-row
            align-items-center
            justify-content-center justify-content-sm-end
            mx-0
            w-100
          "
        >
          <FormButton
            variant="light"
            isBlock
            class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary"
            @click.native="cancel"
            >Cancel</FormButton
          >
          <FormButton
            variant="danger"
            isBlock
            :isLoading="isBannerLoading || isTypeLoading"
            :disabled="isBannerLoading || isTypeLoading || hideBtn"
            class="my-2 my-sm-0 ml-0 ml-sm-2 font-weight-normal text-white"
            type="button"
            @click.native="submitFn"
            >Submit</FormButton
          >
        </div>
      </template>
      <!-- custom close icon -->
      <template v-slot:modal-header-close>
        <font-awesome-icon
          icon="times"
          class="text-secondary"
          @click="closeModal()"
          aria-label="Close Modal"
        ></font-awesome-icon>
      </template>
      <b-row
        class="
          d-flex
          flex-row
          align-items-start
          justify-content-start
          w-100
          px-2
        "
        no-gutters
      >
        <b-col cols="12" v-if="selected == 'rectangle'">
          <ImageCropper
            :width="720"
            :height="540"
            @getBase64="getBase64CoverFn"
            :key="key"
            :initialImage="img[0]"
            :imageUrl="bannerImagePropUrl"
          />
        </b-col>

        <b-col cols="12" v-if="selected == 'square'">
          <ImageCropper
            :width="150"
            :height="150"
            @getBase64="getBase64CoverFn"
            :key="key"
            :initialImage="img[0]"
            :imageUrl="bannerImagePropUrl"
          />
        </b-col>

        <b-col cols="12" v-if="selected == 'longBar'">
          <ImageCropper
            :width="1080"
            :height="180"
            @getBase64="getBase64CoverFn"
            :key="key"
            :initialImage="img[0]"
            :imageUrl="bannerImagePropUrl"
          />
        </b-col>
        <!-- <template v-if="!bannerImageUrl">
          <b-button
            variant="light"
            class="
              d-flex
              flex-column flex-shrink-0
              align-items-center
              justify-content-center
              mr-2
              bg-white
              border border-light
              shadow-sm
              rounded-lg
              banner-add-btn
            "
            @click="selectBannerFile"
          >
            <font-awesome-icon
              class="text-prime-gray"
              icon="plus"
              size="lg"
              aria-label="Create Prize"
            ></font-awesome-icon>
            <span class="d-inline mt-2 text-prime-gray">Add a new image</span>
          </b-button>
          <input
            type="file"
            style="display: none"
            ref="bannerFileInput"
            accept="image/*"
            @change="selectedBanner"
          />
        </template>
        <template v-else>
          <b-button
            variant="light"
            class="
              d-flex
              flex-column flex-shrink-0
              align-items-center
              justify-content-center
              mr-2
              bg-white
              border border-light
              shadow-sm
              rounded-lg
              banner-add-btn
            "
            @click="selectBannerFile"
          >
            <b-img
              v-if="bannerImageUrl"
              class="banner-add-btn"
              :src="bannerImageUrl"
            />
          </b-button>
          <input
            type="file"
            style="display: none"
            ref="bannerFileInput"
            accept="image/*"
            @change="selectedBanner"
          />
        </template> -->
        <b-col cols="12" lg="8">
          <h6 class="font-primary mt-4 mt-lg-0">Banner Type</h6>
          <b-row
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-center
              w-100
              px-3
              mt-3
            "
          >
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-1"
                v-model="selected"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radio-options"
                class="custom-checkbox"
              ></b-form-radio-group>
            </b-form-group>
          </b-row>
          <template v-if="selected == 'rectangle'">
            <h6 class="font-primary">Image guidelines</h6>
            <b-row>
              <b-col class="d-flex flex-column">
                <ul class="text-left mt-2">
                  <small>
                    <li class="sub-points">
                      Banner image must be at least
                      <span class="font-weight-bold"> 720x540px</span> in size,
                      with an aspect ratio of 4:3.
                    </li>
                    <li class="sub-points mt-1">
                      JPEG is the preferred format, but you may also use PNG.
                    </li>
                    <li class="sub-points mt-1">
                      Ensure that the images have a clean background.
                    </li>
                    <li class="sub-points mt-1">
                      Images must be less than 5MB in size.
                    </li>
                  </small>
                </ul>
              </b-col>
            </b-row>
          </template>
          <template v-if="selected == 'square'">
            <h6 class="font-primary">Image guidelines</h6>
            <b-row>
              <b-col class="d-flex flex-column">
                <ul class="text-left mt-2">
                  <small>
                    <li class="sub-points">
                      Banner image must be at least
                      <span class="font-weight-bold"> 150x150px</span> in size,
                      with an aspect ratio of 1:1.
                    </li>
                    <li class="sub-points mt-1">
                      JPEG is the preferred format, but you may also use PNG.
                    </li>
                    <li class="sub-points mt-1">
                      Ensure that the images have a clean background.
                    </li>
                    <li class="sub-points mt-1">
                      Images must be less than 5MB in size.
                    </li>
                  </small>
                </ul>
              </b-col>
            </b-row>
          </template>
          <template v-if="selected == 'longBar'">
            <h6 class="font-primary">Image guidelines</h6>
            <b-row>
              <b-col class="d-flex flex-column">
                <ul class="text-left mt-2">
                  <small>
                    <li class="sub-points">
                      Banner image must be at least
                      <span class="font-weight-bold"> 1080x180px</span> in size,
                      with an aspect ratio of 6:1.
                    </li>
                    <li class="sub-points mt-1">
                      JPEG is the preferred format, but you may also use PNG.
                    </li>
                    <li class="sub-points mt-1">
                      Ensure that the images have a clean background.
                    </li>
                    <li class="sub-points mt-1">
                      Images must be less than 5MB in size.
                    </li>
                  </small>
                </ul>
              </b-col>
            </b-row>
          </template>
        </b-col>
        <b-overlay
          :show="isAPILoading || isBannerLoading || isTypeLoading"
          no-wrap
        ></b-overlay>
      </b-row>
      <b-row
        class="
          d-flex
          flex-row
          align-items-start
          justify-content-start
          w-100
          py-2
        "
        no-gutters
      >
        <div
          v-if="bannerFileError.status == false"
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {{ bannerFileError.message }}
          <!-- <button
          @click="bannerFileError.status = null"
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button> -->
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
// services
import FormButton from "@/components/Form/FormButton";
import ImageCropper from "@/components/ImageUpload/ImageCropper";

import {
  UpdateBanner,
  GetData,
  // CreateLayout,
} from "@/services/campaignTemplate.service";
export default {
  name: "BannerModel",
  components: {
    FormButton,
    ImageCropper,
  },
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
  },
  data() {
    return {
      isAPILoading: false,
      isBannerLoading: false,
      isTypeLoading: false,
      selected: "rectangle",
      options: [
        { text: "Rectangle", value: "rectangle" },
        { text: "Square", value: "square" },
        { text: "Long bar", value: "longBar" },
      ],
      bannerImageUrl: null,
      bannerFileError: {
        status: null,
        message: "",
      },
      hideBtn: false,
      bannerImage: null,
      key: Number(new Date()),
      img: false,
      test: null,
      bannerImagePropUrl: null,
    };
  },
  watch: {
    selected(val) {
      if (val) this.bannerImageUrl = null;
      this.bannerFileError.message = null;
      this.bannerFileError.status = null;
    },
    // async bannerImageUrl(val) {
    //   const base64 = await fetch(val);
    //   const blob = await base64.blob();
    //   this.test = blob;
    // },
  },
  async mounted() {
    // start and end the loading when all the APIs are called
    this.isAPILoading = true;
    this.isBannerLoading = true;
    this.isTypeLoading = true;
    await this.initFn();
    this.isAPILoading = false;
    this.isBannerLoading = false;
    this.isTypeLoading = false;
  },
  methods: {
    getBase64CoverFn(val) {
      this.bannerImageUrl = val;
    },
    closeModal() {
      this.$emit("onClose");
    },
    async submitFn() {
      if (this.bannerImageUrl.chosenFile == null) {
        try {
          this.isBannerLoading = true;
          const formData = new FormData();
          try {
            formData.append(
              "bannerImage",
              this.bannerImageUrl.generateDataUrl()
            );
          } catch (error) {
            console.log(error);
          }
          formData.append("type", this.selected);

          let { data } = await UpdateBanner(formData);

          if (data && data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Campaign Template",
              content: "Data was saved successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.$emit("reloadFn");
            await this.initFn();
          } else {
            let payloadNotify = {
              isToast: true,
              title: "ERROR! Campaign Template",
              content: `Unable to save data! ${
                data ? data.msg : "Please try again."
              }.`,
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign Template",
            content: "Failed to save data! please check again: ",
            list: [error.message],
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } finally {
          this.isBannerLoading = false;
        }
      } else {
        var errResult = this.errorFn();
        if (errResult.length == 0) {
          // if (this.bannerImage) {
          try {
            this.isBannerLoading = true;
            const formData = new FormData();
            try {
              formData.append(
                "bannerImage",
                this.bannerImageUrl.generateDataUrl()
              );
            } catch (error) {
              console.log(error);
            }
            formData.append("type", this.selected);

            let { data } = await UpdateBanner(formData);

            if (data && data.code == 200 && data.message == "success") {
              let payloadNotify = {
                isToast: true,
                title: "Campaign Template",
                content: "Data was saved successfully",
                variant: "success",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
              this.$emit("reloadFn");
              await this.initFn();
            } else {
              let payloadNotify = {
                isToast: true,
                title: "ERROR! Campaign Template",
                content: `Unable to save data! ${
                  data ? data.msg : "Please try again."
                }.`,
                variant: "danger",
              };
              this.$store.dispatch("notification/setNotify", payloadNotify);
            }
          } catch (error) {
            let payloadNotify = {
              isToast: true,
              title: "ERROR! Campaign Template",
              content: "Failed to save data! please check again: ",
              list: [error.message],
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          } finally {
            this.isBannerLoading = false;
          }
        } else this.handleSubmitError(errResult);
      }
    },
    handleSubmitError(errDetails) {
      this.isAPILoading = false;
      this.apiConfirmationText = null;

      let payloadNotify = {
        isToast: true,
        title: "ERROR! Unable to save data",
        // content: errDetails ,
        content: !Array.isArray(errDetails) ? errDetails : null,
        list: Array.isArray(errDetails) ? errDetails : null,
        variant: "danger",
      };

      this.$store.dispatch("notification/setNotify", payloadNotify);
    },
    async initFn() {
      try {
        this.isAPILoading = true;
        let { data } = await GetData();
        if (data.code == 200 || data.message == "success") {
          let details = data.content;
          this.bannerImage = details.bannerImage;
          this.bannerImageUrl = details.bannerImage
            ? details.bannerImage[0]
            : null;
          this.bannerImagePropUrl = this.bannerImageUrl;
          this.selected = details.bannerType ? details.bannerType : "rectangle";
        } else {
          this.isAPILoading = false;
          let payloadNotify = {
            isToast: true,
            title: "ERROR! Campaign details",
            content: "Failed to retrieve data! Please try again.",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      } catch (error) {
        this.isAPILoading = false;
        console.log("ERROR BM1");
      }
    },

    errorFn() {
      var errorList = [];
      // if (!this.bannerImageUrl) {
      //   this.bannerFileError.status = false;
      //  this.bannerFileError.message = "Please upload the banner image";

      //   errorList.push("Please upload the banner image");
      // }

      // else {
      //   if ("File" in window && this.bannerImage instanceof File == false)
      //     errorList.push("Please upload a valid file as the banner file");

      if (
        this.bannerImageUrl &&
        this.bannerImageUrl.chosenFile.type != "image/jpeg" &&
        this.bannerImageUrl.chosenFile.type != "image/png"
      )
        errorList.push(
          "Please upload a valid image file in either .jpeg or .png formats"
        );

      if (
        this.bannerImageUrl &&
        this.bannerImageUrl.chosenFile.size &&
        this.bannerImageUrl.chosenFile.size > 5 * 1024 * 1024 //5 mb
      ) {
        errorList.push(
          "Please upload an image file that is less than 5MB in size"
        );
      }

      if (this.selected == "rectangle" && this.bannerImageUrl) {
        if (
          this.bannerImageUrl.chosenFile.width < 720 ||
          this.bannerImageUrl.chosenFile.height < 540
        ) {
          if (
            this.bannerImageUrl.chosenFile.width /
              this.bannerImageUrl.chosenFile.height !=
            1.3333333333333333
          ) {
            errorList.push(
              "Please select a image with aspect ratio 4:3 and at least 720x540px"
            );
          }
        }
      }

      if (this.selected == "square" && this.bannerImageUrl) {
        if (
          this.bannerImageUrl.chosenFile.width < 150 ||
          this.bannerImageUrl.chosenFile.height < 150
        ) {
          if (
            this.bannerImageUrl.chosenFile.width /
              this.bannerImageUrl.chosenFile.height !=
            1
          ) {
            errorList.push(
              "Please select a image with aspect ratio 1:1 and at least 150x150px"
            );
          }
        }
      }

      if (this.selected == "longBar" && this.bannerImageUrl) {
        if (
          this.bannerImageUrl.chosenFile.width < 1080 ||
          this.bannerImageUrl.chosenFile.height < 180
        ) {
          if (
            this.bannerImageUrl.chosenFile.width /
              this.bannerImageUrl.chosenFile.height !=
            6
          ) {
            errorList.push(
              "Please select a image with aspect ratio 6:1 and at least 1080x180px"
            );
          }
        }
      }
      // }
      return errorList;
    },
  },
};
</script>

<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}
.banner-add-btn {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 150ms ease-in;
}
.banner-add-btn:hover {
  background-color: var(--light) !important;
}
.custom-checkbox >>> .custom-control-label::before {
  border-color: var(--light-gray);
}
.form-group >>> select.custom-select {
  border-color: var(--gray-550);
}
.form-group >>> label {
  color: var(--muted-gray);
}
.form-group >>> .custom-select {
  color: var(--muted-gray);
}
.custom-checkbox
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: var(--light-gray);
}
.custom-checkbox
  >>> .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: var(--white);
  border-color: var(--light-gray);
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
