<template>
  <!-- delete modal -->
  <b-modal
    id="delete-video-row-modal"
    ref="deleteItemModal"
    :title="
      type == 'logo'
        ? 'Are you sure you want to delete this logo?'
        : type == 'banner'
        ? 'Are you sure you want to delete this banner?'
        : type == 'backgroundImg'
        ? 'Are you sure you want to delete this background image?'
        : type == 'blackList'
        ? 'Are you sure you want to delete this word from the blackList Library?'
        : type
    "
    size="lg"
    header-class="font-primary"
    header-bg-variant="white"
    header-text-variant="secondary"
    header-border-variant="white"
    footer-bg-variant="gray-300"
    footer-border-variant="light"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 w-100"
      >
        <FormButton
          variant="light"
          isBlock
          class="my-2 my-sm-0 mr-0 mr-sm-2 font-weight-normal text-secondary"
          type="button"
          @click.native="cancel"
          >Cancel</FormButton
        >
        <FormButton
          variant="danger"
          isBlock
          :disabled="apiLoader"
          :isLoading="apiLoader"
          class="my-2 my-sm-0 ml-0 ml-sm-2 font-weight-normal text-white"
          type="button"
          @click.native="deleteFn"
          >Delete</FormButton
        >
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <font-awesome-icon
        icon="times"
        class="text-secondary"
        @click="closeModal()"
        aria-label="Close Modal"
      ></font-awesome-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <p class="text-left text-prime-gray mb-4" v-if="type == 'logo'">
        Are you sure you want to delete this logo? All the data will be
        permanently removed. This action cannot be undone.
      </p>
      <p class="text-left text-prime-gray mb-4" v-if="type == 'banner'">
        Are you sure you want to delete this banner ? All the data will be
        permanently removed. This action cannot be undone.
      </p>
      <p class="text-left text-prime-gray mb-4" v-if="type == 'backgroundImg'">
        Are you sure you want to delete this background image compliance slide
        All the data will be permanently removed. This action cannot be undone.
      </p>
      <p class="text-left text-prime-gray mb-4" v-if="type == 'blackList'">
        Are you sure you want to delete this word from the blackList Library?
        All the data will be permanently removed. This action cannot be undone.
      </p>
    </b-row>
  </b-modal>
</template>

<script>
// components
import FormButton from "@/components/Form/FormButton";

export default {
  name: "DeleteModal",
  components: {
    FormButton,
  },
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "Are you sure you want to delete this item?",
      type: String,
    },
    type: {
      default: null,
      type: String,
    },
    itemContent: {
      default: null,
    },
    apiLoader: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    async deleteFn() {
      if (!this.itemContent) return false;
      if (this.itemContent && this.type == "logo") {
        this.$emit("deleteLogo", this.itemContent);
      }
      if (this.itemContent && this.type == "banner") {
        this.$emit("deleteBanner", this.itemContent);
      }
      if (this.itemContent && this.type == "backgroundImg") {
        this.$emit("deleteBackgroundImg", this.itemContent);
      }
      if (this.itemContent && this.type == "blackList") {
        this.$emit("deleteWordBlackList", this.itemContent);
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>
