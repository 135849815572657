import Instance from "./instance";

export const CreateLayout = async (payload) => {
  try {
    return await Instance.instanceToken.post("/campaignLayout", payload);
  } catch (error) {
    return error;
  }
};

export const UpdateLayout = async (payload) => {
  try {
    return await Instance.instanceFileUpload.put("/campaignLayout", payload);
  } catch (error) {
    return error;
  }
};

export const UpdateBackgroundImage = async (payload) => {
  try {
    return await Instance.instanceFileUpload.put(
      "/campaignLayout/backgroundImageUpdate",
      payload
    );
  } catch (error) {
    return error;
  }
};

export const UpdateBanner = async (payload) => {
  try {
    return await Instance.instanceFileUpload.put(
      "/campaignLayout/bannerUpdate",
      payload
    );
  } catch (error) {
    return error;
  }
};

export const UpdateLogo = async (payload) => {
  try {
    return await Instance.instanceFileUpload.put(
      "/campaignLayout/logoUpdate",
      payload
    );
  } catch (error) {
    return error;
  }
};

export const GetData = async () => {
  try {
    return await Instance.instanceFileUpload.get("/campaignLayout/byId");
  } catch (error) {
    return error;
  }
};

export const DeleteLogo = async () => {
  try {
    return await Instance.instanceFileUpload.delete(
      "/campaignLayout/logoDelete"
    );
  } catch (error) {
    return error;
  }
};

export const DeleteBanner = async () => {
  try {
    return await Instance.instanceFileUpload.delete(
      "/campaignLayout/bannerDelete"
    );
  } catch (error) {
    return error;
  }
};

export const DeleteBackgroundImg = async () => {
  try {
    return await Instance.instanceFileUpload.delete(
      "/campaignLayout/backgroundImageDelete"
    );
  } catch (error) {
    return error;
  }
};
